import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ProjectsPage = ({ data }) => (
  <Layout>
    <SEO
      title="Home"
      keywords={[`gatsby`, `application`, `react`, `portfolio`]}
    />
    <div className="project-list">
      {data.allProjectsJson.edges.map(project => (
        <div key={project.node.id} className="project-list__item">
          <div className="project-list__thumbnail">
            {/* <Img fluid={project.node.thumbnailImage.childImageSharp.fluid} /> */}
          </div>
          <div className="project-list__content">
            <a href={project.node.url}>
              <p>{project.node.title}</p>
            </a>
            <div className="project-list__excerpt">
              {project.node.description}
            </div>
          </div>
        </div>
      ))}
    </div>
  </Layout>
)

export default ProjectsPage

export const projectsQuery = graphql`
  query {
    allProjectsJson(sort: { order: ASC, fields: [date] }) {
      edges {
        node {
          id
          title
          date
          description
          url
          thumbnailImage {
            childImageSharp {
              fluid(maxWidth: 1200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
